import apiClient from '@/services/axios'

export const getProductVariantTokopedia = ({ channel_id, business_id, params }) => {
  return apiClient({
    method: 'get',
      url: `channel/tokopedia_id/product/query/${channel_id}/category/variant`,
      headers: {
        'Business-Id': business_id,
      },
      params,
  })
}

export const getWarehouseTokopedia = ({ channel_id, business_id }) => {
  return apiClient({
    method: 'get',
    url: `/channel/tokopedia_id/integration/query/warehouse/map/list/${channel_id}`,
    headers: {
      'Business-Id': business_id,
    },
  })
}

export const confirmShippingTokopedia = ({ order_id, business_id, data }) => {
  return apiClient({
    method: 'post',
    url: `/channel/tokopedia_id/order/cmd/orders/${order_id}/confirm-shipping`,
    headers: {
      'Business-Id': business_id,
    },
    data,
  })
}

export const confirmIntegrationTokopedia = ({ id, business_id, data }) => {
  return apiClient({
    method: 'post',
    url: `/channel/tokopedia_id/integration/cmd/${id}/confirm`,
    headers: {
      'Business-Id': business_id,
    },
    data,
  })
}

export const activateDeactivateProductTokopedia = ({ business_id, channel_id, status, channel_product_id, params }) => {
  return apiClient({
    method: 'post',
    url: `channel/tokopedia_id/product/cmd/${channel_id}/${status}/${channel_product_id}`,
    headers: {
      'Business-Id': business_id,
    },
    params,
  })
}