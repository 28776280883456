import { ref } from 'vue'
import { getPriceFormula, getPriceFormulaDetail, getPriceCalculation } from '@/api/price'

const formulas = ref([

])

const fetchFormulaDetail = async ({ businessId, formulaId }) => {
  const { data: { data } } = await getPriceFormulaDetail({
    business_id: businessId,
    formula_id: formulaId,
  })

  return data
}

export const useFormula = () => {
  const fetchPriceFormula = async ({ businessId, page = 1, limit = 500}) => {
    const { data: { data } } = await getPriceFormula({
      business_id: businessId,
      params: {
        business_id: businessId,
        page,
        limit,
      },
    })

    formulas.value = data
  }

  /**
   * 
   * @param {{
   *  businessId: string,
   *  formulaId: string,
   *  productVariables: import('@/types/product').ProductVariablePrice[]
   * }} param 
   */
  const calculate = async ({ businessId, formulaId, productVariables = [] }) => {
    const { data: { data } } = await getPriceCalculation({
      business_id: businessId,
      params: {
        price_formula_id: formulaId,
        product_variables: productVariables.map(v => `${v.id};${v.value}`).join(','),
      },
    })

    return data?.price
  }

  const findFormula = (formulaId) => formulas.value.find(f => f.id === formulaId)

  return {
    formulas,
    fetchPriceFormula,
    fetchFormulaDetail,
    findFormula,
    calculate,
  }
}
