<script>
/**
 * @template T
 * @typedef {import('@vue/composition-api').Ref<T>} Ref
 */

/**
 * @typedef {import('@/types/product').ProductFormModel} ProductFormModel
 */
import { defineComponent, nextTick, ref, watch, computed, getCurrentInstance } from 'vue'
import { validate, ValidationProvider } from 'vee-validate';
import { channelImageDraft, channelEventProcess, uploadChannelImage } from '@/api/channels/index'
import debounce from 'lodash/debounce';

const ACCEPTED_EXTENSION = ['jpg', 'jpeg', 'png', 'bmp'];
const MAX_IMAGE = 15;
const MAX_IMAGE_SIZE_IN_KB = 1000; // 10_000
const MIN_WIDTH = 300
const MIN_HEIGHT = 300

/**
 * @param {ProductFormModel['images'][0]} image 
 */
const getImageUrl = (image) => {
  if (image.url) return `${image.url}?tr=w-200,h-200,cm-pad_resize,bg-FFFFFF`
  if (image.file) return URL.createObjectURL(image.file)

  return null
}

export default defineComponent({
  components: {
    ValidationProvider,
  },
  props: {
    value: Object,
    permission: Array,
    idDraft: String,
    draftImage: Array,
    loading: Boolean,
    submitting: Boolean,
  },
  setup(props, { emit }) {
    const { $message, $store, $route } = getCurrentInstance().proxy.$root
    /** @type {Ref<ProductFormModel>} */
    const model = ref(props.value)
    const edit = ref(false)
    watch(model, () => emit('input', model.value))
    watch(() => props.value, () => {
      model.value = props.value
    })

    /** @type {Ref<ProductFormModel['images']>} */
    const images = ref(props.value.images || []);
    const eventProcess = ref(false);
    watch(() => props.value.images, (value) => {
      images.value = value || [];
    }, { deep: true });
    watch(() => props.draftImage, () => {
      const data = images.value?.map(v => {
        const temp = {
          ...v,
          id: getId(v.url), 
        }
        return temp
      })
      images.value = data
      emit('change', data)
    }, { deep: true });
    const activeImages = computed(() => images.value.filter(({ deleted }) => !deleted));

    const isCanAddImage = computed(() => 
      $route.query.edit ? edit.value && images.value.length < MAX_IMAGE && props.permission.includes('WRITE') : images.value.length < MAX_IMAGE && props.permission.includes('WRITE') );

    const getId = (e) => {
      const filter = props.draftImage.filter(item => {
        return item.url_list[0] === e
      })
      if (filter.length > 0) return filter[0].image_id
    }

    const validateDimension = (file) => {
      return new Promise((resolve, reject) => {
        const img = new Image()
        img.src = window.URL.createObjectURL(file)
        img.addEventListener('load', e => {
          const { naturalWidth, naturalHeight, src } = e.target
          if (naturalWidth < MIN_WIDTH || naturalHeight < MIN_HEIGHT) {
            reject(file)
          } else {
            resolve(file)
          }
          window.URL.revokeObjectURL(src)
        })
      })
    }
    
    const onFileChanged = async (e) => {
      // e.preventDefault()
      const files = Array.from(e.target.files)
      const remainingImageCanUpload = MAX_IMAGE - images.value.length

      if (files.length > remainingImageCanUpload) {
        return $message.error(`You can upload maximum ${remainingImageCanUpload} images left`)
      }

      const results = await validateImages(files)

      const invalidImage = results.find(({ result }) => !result.valid)
      if (invalidImage) {
        $message.error(invalidImage.result.errors[0], 5)
        return
      } else {
        await Promise.all(files.map(async f => await validateDimension(f)))
          .then(() => {
            addImages(files)
          })
          .catch(() => {
            $message.error(`Dimensi foto minimal ${MIN_WIDTH}x${MIN_HEIGHT} px`)
            return
          })
      }

      e.target.value = ''
    }

    /**
     * @param {File[]} files 
     * @typedef {{result: import('vee-validate/dist/types/types').ValidationResult, file: File }} ValidationResult
     * @returns {Promise<ValidationResult[]>}
     */
    const validateImages = async (files) => {
      return Promise.all(
        Array.from(files).map(async (file) => {
          const result = await validate(
            file,
            [
              `ext:${ACCEPTED_EXTENSION.join(',')}`,
              `size:${MAX_IMAGE_SIZE_IN_KB}`,
            ].join('|'), 
            { name: 'File' },
          )

          return { result, file }
        }),
      )
    }

    const addImages = async (files) => {
      images.value.push(
        ...files.map(file => ({
          file,
          uploading: false,
          failed: null,
          message: null,
          deleted: false,
        })),
      )
      
      await uploadImages()
    }

    const uploadImage = async (image) => {
      image.uploading = true
      image.failed = null
      try {
        const { data } = await uploadChannelImage({
          business_id: $route.query.business_id || $store.state.user.businessList[0].business_id,
          channel_code: $route.query.channel_code,
          channel_id: $route.params.id,
          user_id: $store.state.user.id,
          product_id: props.idDraft || model.value.id,
          file: image.file,
          image_type: 1,
        })
        if (data.message === 'Success') {
          getEventProcess(data.data.request_id)
        }
        image.id = data.data.request_id
        image.uploading = data.data.status === 'ON_PROGRESS' ? true : false
        image.failed = data.data.status === 'ON_PROGRESS' ? 'Sedang Proses Upload Gambar, Mohon Ditunggu.' : null
        return data
      } catch (err) {
        const maxUpload = 'Maximum upload size exceeded'
        const msg = err?.response?.data?.message || 'Failed'
        image.uploading = false
        image.failed = msg.includes(maxUpload) ? maxUpload : msg
        throw err
      }
    }

const fetchDraftImage = async (request_id) => {
  const response = await channelImageDraft({
    business_id: $route.query.business_id || $store.state.user.businessList[0].business_id,
    channel_code: 'tokopedia_id',
    channel_id: $route.params.id,
    user_id: $store.state.user.id,
    product_id: props.idDraft || model.value.id,
    params: { request_id },
  })
  if (response.data.message === "Success" || response.data.images.length > 0) {
    const img = images.value
    const unuploadedImages = img.filter(i => !i.ref_id)
    unuploadedImages.map(async (image) => {
      image.ref_id = $route.query.edit ? response.data.images[0].image_id : response.data.data.image[0].image_id
    })
  }
}

const getEventProcess = debounce(async (request_id) => {
  eventProcess.value = false
  const response = await channelEventProcess({
    business_id: $route.query.business_id || $store.state.user.businessList[0].business_id,
    channel_code: 'tokopedia_id',
    channel_id: $route.params.id,
    request_id,
  })
  if (response.data.data.status === "FAILED") {
    const img = images.value
    const unuploadedImages = img.filter(i => !i.ref_id)
    unuploadedImages.map(async (image) => {
      image.uploading = false
      image.failed = response.data.data.message || response.data.data.status
    })
    eventProcess.value = false
  } else if (response.data.data.status === "ON_PROGRESS") {
    getEventProcess(request_id)
  } else if (response.data.data.status === "FINISHED") {
    const temp = response.data.data.message
    const img = images.value
    const unuploadedImages = img.filter(i => !i.ref_id)
    unuploadedImages.map(async (image) => {
      image.uploading = false
      image.failed = null
      if (!$route.query.edit) {
        image.ref_id = temp.fileId
      }
      image.url = temp.url
    })
    eventProcess.value = false
    if (!$route.query.edit) {
      save()
    }
    fetchDraftImage(request_id)
  }
}, 1000)

    const uploadImages = async () => {
      if (!props.value.id) return // bail out when product id is null
      const unuploadedImages = images.value.filter(i => !i.ref_id)
      
      const results = await Promise.all(
        unuploadedImages.map(async (image) => await uploadImage(image)),
      )

      // if (results.length && saveAfterUpload) save()
      
      return results
    }

    watch(() => props.value.id, (value) => {
      if (value) nextTick(() => uploadImages())
    })

    const setAsPrimary = (index) => {
      images.value.splice(0, 0, images.value.splice(index, 1)[0]);
      save()
    }
    const setAsThumbnail = (index) => {
      images.value.splice(1, 0, images.value.splice(index, 1)[0]);
      save()
    }
    const deleteImage = (index) => {
      images.value[index].deleted = true
      save()
    }
    const save = () => {
      const imagetemp = [...images.value] 
      emit('change', imagetemp.map((image, index) => ({
        ...image,
        order: index,
        tags: [ 
          index === 0 ? 'SHOW' : undefined,
          index === 1 || imagetemp.length === 1 ? 'THUMB' : undefined,
        ].filter(Boolean),
      })))
    }

    const handleEdit = () => {
      edit.value = !edit.value
      emit('changeEditable', {photo: edit.value})
      if (edit.value === false) {
        const filter = images.value.filter(v => {return !v.channel_image_id})
        if (filter.length > 0) deleteImage(activeImages.value.length -1)
        const firstErrorElement = document.querySelector('#photo-product')
        window.scrollTo({
          behavior: 'smooth',
          top: firstErrorElement.getBoundingClientRect().top + window.pageYOffset - 225,
        })
      }
    }

    const submitEdit = () => {
      const payload = {
        section: 2,
        detail: {
          images: model.value.images.map(item => {
            const temp = {
              id: item.id,
              // id: item.ref_id,
              }
              return temp
            }),
            videos: [
              {
                source: 'youtube',
                    url: model.value.video_url || null,
                },
              ],
        },
      }
      console.log({images: model.value.images})
      emit('saveEdit', payload)
    }

    watch(() => props.submitting, (value) => {
        if (!value && edit.value) {
          handleEdit()
        }
      }, { deep: true, immediate: true })

    return {
      model,
      images,
      activeImages,
      getImageUrl,
      addImages,
      isCanAddImage,
      onFileChanged,
      ACCEPTED_EXTENSION,
      setAsPrimary,
      setAsThumbnail,
      deleteImage,
      uploadImages,
      minWidth: MIN_WIDTH,
      minHeight: MIN_HEIGHT,
      edit,
      handleEdit,
      submitEdit,
    }
  },
})
</script>

<template>
  <a-card>
    <a-skeleton v-if="loading" active />
    <div v-else>
      <div class="mb-5 d-flex">
        <div>
          <h4 id="photo-product">
            {{ $t('product.photo_video') }}
          </h4>
        </div>
        <!-- FIXME: hide edit button for tokopedia channel -->
        <div v-if="$route.query.edit && !edit && permission.includes('WRITE')" class="ml-auto">
          <a-button
            type="primary"
            size="large"
            :ghost="edit ? false : true"
            @click="handleEdit"
          >
            Edit
          </a-button>
        </div>
      </div>

      <section class="mb-5">
        <div class="font-weight-semibold">
          {{ $t('product.photo_title') }}
        </div>
        <div class="text-muted mb-4">
          {{ $t('product.foto_video_description', { minWidth, minHeight }) }}
        </div>

        <div class="image-wrapper">
          <div
            v-for="(image, index) in activeImages"
            :key="`${image}_${index}`"
          >
            <div v-if="$route.query.edit && !edit" class="image-item">
              <img :src="getImageUrl(image)" />
              <div v-if="image.uploading" class="loading">
                <a-icon type="loading" spin />
              </div>
              <a-popover trigger="hover" placement="top">
                <template #content>
                  <div class="failed-reason" style="font-size: 0.75rem; max-width: 350px">
                    {{ image.failed }}
                  </div>
                </template>
                <div v-if="image.failed" class="failed" @click="uploadImages()">
                  <a-icon type="exclamation-circle" />
                  <div>Failed</div>
                </div>
              </a-popover>
              <div v-if="index === 0" class="image-label">
                Utama
              </div>
              <div v-if="index === 1" class="image-label">
                Thumbnail
              </div>
            </div>
            <a-popover
              v-else
              placement="bottom"
              :trigger="permission.includes('WRITE') ? 'hover' : 'contextmenu'"
            >
              <template #content>
                <div style="width: 150px">
                  <a-button
                    v-if="index !== 0 && !image.failed"
                    type="primary"
                    size="small"
                    shape="round"
                    class="mb-2 w-100 text-small"
                    @click="setAsPrimary(index)"
                  >
                    Set Utama
                  </a-button>
                  <a-button
                    v-if="index !== 1 && !image.failed"
                    ghost
                    type="primary"
                    size="small"
                    shape="round"
                    class="mb-2 w-100"
                    @click="setAsThumbnail(index)"
                  >
                    Set Thumbnail
                  </a-button>

                  <a-popconfirm
                    title="Are you sure want to remove this image ?"
                    ok-text="Remove"
                    cancel-text="Cancel"
                    @confirm="deleteImage(index)"
                  >
                    <a-button class="w-100" icon="delete" size="small" shape="round" />
                  </a-popconfirm>
                </div>
              </template>
              <div class="image-item">
                <img :src="getImageUrl(image)" />

                <div v-if="image.uploading" class="loading">
                  <a-icon type="loading" spin />
                </div>

                <a-popover trigger="hover" placement="top">
                  <template #content>
                    <div class="failed-reason" style="font-size: 0.75rem; max-width: 350px">
                      {{ image.failed }}
                    </div>
                  </template>
                  <div v-if="image.failed && image.failed !=='Sedang Proses Upload Gambar, Mohon Ditunggu.'" class="failed" @click="uploadImages()">
                    <a-icon type="exclamation-circle" />
                    <div>Failed</div>
                  </div>
                </a-popover>

                <div v-if="index === 0" class="image-label">
                  Utama
                </div>
                <div v-if="index === 1" class="image-label">
                  Thumbnail
                </div>
              </div>
            </a-popover>
          </div>
          <div v-if="isCanAddImage">
            <label class="image-item uploader">
              <input
                type="file"
                class="d-none"
                multiple
                :accept="ACCEPTED_EXTENSION.map(e => `.${e}`).join(',')"
                @change="onFileChanged"
              />
              <div>
                <a-icon type="plus" />
                <div>Gambar</div>
              </div>
            </label>
          </div>
        </div>

        <ValidationProvider
          v-slot="{ errors }"
          tag="div"
          name="Foto"
          rules="required"
          :custom-messages="{
            required: 'Upload minimal 1 gambar produk',
          }"
        >
          <input type="hidden" :value="activeImages" />
          <div v-if="errors.length" class="ant-form-explain text-danger">
            {{ errors[0] }}
          </div>
        </ValidationProvider>
      </section>
      <div v-if="$route.query.edit && edit" class="mt-3 py-3 text-right footer">
        <a-button
          size="large"
          type="primary"
          ghost
          :loading="submitting"
          class="px-5 mr-3 ml-auto"
          @click="handleEdit"
        >
          {{ $t('utils.cancel') }}
        </a-button>

        <a-button
          size="large"
          type="primary"
          class="px-5"
          :loading="submitting"
          @click="submitEdit"
        >
          {{ $t('utils.save') }}
        </a-button>
      </div>
    </div>
  </a-card>
</template>

<style scoped lang="scss">
.image-wrapper {
  display: flex;
  flex-wrap: wrap;
  margin-left: -.75rem;
  margin-right: -.75rem;

  > * {
    padding: 0 .75rem;
  }

  .image-item {
    position: relative;
    border: 1px solid #ccc;
    border-radius: .5rem;
    overflow: hidden;
    width: 150px;
    height: 150px;
    margin-bottom: 1.5rem;

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
      object-position: center;
    }
    

    &.uploader {
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #ccc;
      cursor: pointer;
      border: 2px dashed #ddd;

      &:hover {
        background-color: #fafafa;
      }

      .anticon {
        font-size: 2rem;
        display: inline-block;
        margin-bottom: .5rem;
      }
    }

    .image-label {
      padding: .15rem .65rem;
      background: var(--kit-color-primary);
      color: white;
      font-size: .8rem;
      position: absolute;
      bottom: 0;
      border-radius: 0 .5rem 0 0;
    }

    .loading,
    .failed {
      position: absolute;
      inset: 0;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      background-color: rgba(0, 0, 0, .2);
      
      .anticon {
        font-size: 3rem;
        color: var(--kit-color-primary);
      }
    }

    .failed {
      background-color: rgba(255, 255, 255, .9);
      .anticon {
        font-size: 2rem;
        margin-bottom: .5rem;
      }
    }
  }
}
</style>
