<script>
import { computed, defineComponent, ref } from 'vue'
import { useUnits } from '../../useUnits'
import { useCurrency } from '@/composable/useCurrency'
import FormItem from '@/components/Input/FormItem.vue'
import UnitSelector from '../UnitSelector.vue'
import { ValidationProvider } from 'vee-validate';

export default defineComponent({
  components: {
    UnitSelector,
    FormItem,
    ValidationProvider,
  },
  props: {
    value: {
      type: Object,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    label: {
      default() {
        return this.$t('utils.price')
      },
    },
    unit: {
      type: String,
      default: () => undefined,
    },
    vatType: {
      type: String,
      default: () => 'INCLUDE',
    },
    vatAmount: {
      type: Number,
      default: () => 0.11,
    },
  },
  setup(props) {
    const price = computed(() => props.value)
    const { filteredUnitAsOptions } = useUnits()
    const { currencies, format, parseLocaleNumber } = useCurrency()

    const newCurrencies = currencies.filter(currency => ['IDR', 'USD'].includes(currency.value))
    const priceValidationProvider = ref(null)
    const error = computed(() => priceValidationProvider.value?.errors[0])
    const coin = [
        {
          value: 'Koin',
          label: 'Kino Koin',
          symbol: 'Koin',
        },
      ]
    // 100/111

    const amount = computed(() => price.value.value || 0)
    const vat = computed(() => {
      if (!amount.value) return 0

      return props.vatType === 'NONE' ? 0 : Math.round(amount.value * props.vatAmount)
    })
    const dpp = computed(() => {
      if (!amount.value) return 0

      return props.vatType === 'INCLUDE' ? amount.value - vat.value : amount.value
    })
    const total = computed(() => {
      if (!amount.value) return 0

      if (props.vatType === 'INCLUDE') return amount.value

      return amount.value + vat.value
    })

    return {
      price,
      filteredUnitAsOptions,
      newCurrencies,
      coin,
      format,
      parseLocaleNumber,
      priceValidationProvider,
      error,
      vat,
      dpp,
      total,
    }
  },
})
</script>

<template>
  <div style="max-width: 600px" class="addon-dark ant-input-group-wrapper h-48px">
    <div class="ant-input-wrapper ant-input-group" :class="{ 'has-error': error }">
      <div class="ant-input-group-addon bg-white">
        <FormItem
          tag="div"
          rules="required"
          name="currency"
          style="width: 80px; margin: 0 -11px; border-radius: 3px 0 0 3px"
        >
          <a-select 
            v-model="price.currency"
            :options="$route.meta.title === 'Powerbiz - Add Product Redeem' || $route.meta.title === 'Powerbiz - Edit Product Redeem'
              ? coin.map(currency => ({
                value: currency.value,
                label: currency.symbol,
              })) : newCurrencies.map(currency => ({
                value: currency.value,
                label: currency.symbol,
              }))"
            class="w-100 text-dark"
            :disabled="$route.query.id || $route.query.edit ? true : false"
          />
        </FormItem>
      </div>
      <ValidationProvider ref="priceValidationProvider" tag="div" rules="required" :name="label">
        <a-input-number
          v-model.number="price.value"
          :disabled="disabled"
          :formatter="value => format(value)"
          :parser="value => parseLocaleNumber(value)"
          class="h-48px w-100 required price-input-number-input"
        />
      </ValidationProvider>
      <div class="ant-input-group-addon">
        <FormItem 
          v-if="!unit"
          tag="div"
          rules="required"
          style="width: 135px; margin: 0 -11px"
          class="text-white"
        >
          <UnitSelector
            v-model="price.uom"
            :disabled="$route.query.id || $route.query.edit ? true : false"
            @change="val => $emit('changeuom', val)"
          />
        </FormItem>
        <div v-else class="text-white text-left px-3" style="width: 135px; margin: 0 -11px">
          {{ unit }}
        </div>
      </div>
    </div>
    <div v-if="error" class="ant-form-explain text-danger">
      {{ error }}
    </div>
    <!-- <div v-else class="d-flex mt-2 font-italic">
      <div class="px-2">
        DPP = <b>{{ format(dpp) }}</b>
      </div>
      <div class="px-2">
        VAT = <b>{{ format(vat) }}</b>
      </div>
      <div class="px-2">
        Total = <b>{{ format(total) }}</b>
      </div>
    </div> -->
  </div>
</template>
