import { computed, onMounted, onUnmounted, ref } from 'vue'
/**
 * @param {*} emit
 * @returns {{
 *  el: import('@vue/composition-api').Ref,
 *  dirty: import('@vue/composition-api').Ref<boolean>,
 *  validationObserver: import('@vue/composition-api').Ref,
 * }}
 */
export const useSaveSectionOnScroll = (emit) => {
  
  const validationObserver = ref(null)
  const el = ref(null)
  const dirty = computed({
    get() {
      if (!validationObserver.value) return false
      return validationObserver.value.$data.flags.dirty
    },
    set(v) {
      validationObserver.value.$data.flags.dirty = v
    },
  })

  const onScrolled = () => {
    const rect = el.value.$el.getBoundingClientRect()
    const out = rect.bottom <= 150

    if (out && validationObserver.value && dirty.value) {
      const clean = validationObserver.value.reset

      validationObserver.value
        .validate({ silent: true })
        .then((valid) => {
          if (valid) {
            clean()
            emit('offscreen', {
              dirty,
              clean,
              validationObserver: validationObserver.value,
            })
          }
        })
    }
  }

  onMounted(() => {
    document.addEventListener('scroll', onScrolled)
  })

  onUnmounted(() => {
    document.removeEventListener('scroll', onScrolled)
  })

  return { el, dirty, validationObserver }
}
