<template>
  <a-row
    type="flex"
    :gutter="16"
  >
    <a-col :xs="{ span: 24 }" :lg="{ span: 5 }">
      <ol
        v-scroll-spy-link
        v-scroll-spy-active="{ class: 'active' }"
        class="position-sticky p-0 m-0 mt-4"
      >
        <li
          v-for="(label, key) in sections"
          :key="key"
        >
          <a
            class=""
            @click.prevent
          >{{ label }}</a>
        </li>
      </ol>
    </a-col>

    <a-col :xs="{ span: 24 }" :lg="{ span: 19 }">
      <ValidationObserver ref="validationObserver" slim>
        <a-form novalidate @submit.prevent="saveProducts">
          <fieldset
            v-scroll-spy="{ data: 'activeSection', offset: 125 }"
            class="main"
            :disabled="submitting || loadingDetail"
          >
            <DetailProduct
              v-model="model"
              :categories="categories"
              :tokopedia-categories="tokopediaCategories"
              class="mb-5"
              :brands="brands"
              :detail="detailModel"
              :loading="submitting || loadingDetail || loadingVariantTokopedia"
              :rendering="loading"
              :permission="permission"
              :attributes="rawAttributeLists"
              @offscreen="runOnDraft(debouncedSaveDetail)"
              @fetchVariant="fetchVariantProduct"
              @changeEditable="changeEditable"
              @saveEdit="saveEdit"
              @onChangeChannelAttribute="onChangeChannelAttribute"
            />

            <PhotoAndVideo
              v-model="model"
              :id-draft="productIdDraft"
              :draft-image="draftImage"
              :loading="loading"
              :submitting="submitting || loadingDetail"
              class="mb-5"
              :permission="permission"
              @change="debouncedSaveImages"
              @changeEditable="changeEditable"
              @saveEdit="saveEdit"
            />

            <TypeAndPrice
              v-model="model"
              :business="business"
              :loading="loading"
              :catalogs="catalogsModel"
              :submitting="submitting || loadingDetail"
              class="mb-5"
              :tokopedia-attributes="tokopediaAttributes"
              :tokopedia-options="tokopediaOptions"
              :tokopedia-values="tokopediaValues"
              :permission="permission"
              @offscreen="runOnDraft(debouncedSaveCatalogues)"
              @changeuom="onChangeUom"
              @changeEditable="changeEditable"
              @saveEdit="saveEdit"
            />

            <WeightAndShipping
              v-model="model.units"
              :product="model"
              :loading="loading"
              :permission="permission"
              class="mb-5"
              @input:physical="(value) => model.detail.physical = value"
              @cancelBaseUomChange="(value) => {
                model.catalogs.items[0].price.uom = value
              }"
              @offscreen="runOnDraft(debouncedSaveWeightAndShipping)"
              @changeUkuranPaket="changeUkuranPaket"
              @changeEditable="changeEditable"
            />

            <!-- <WarehouseAndInventories
              v-model="model"
              :warehouses="warehouses"
              :loading="loading"
              :submitting="submitting"
              :inventory-list="inventoryList"
              :permission="permission"
              class="mb-5"
              @offscreen="runOnDraft(debouncedSaveWarehousesAndInventories)"
              @changeEditable="changeEditable"
              @createAllocation="createAllocation"
            /> -->

            <!-- <ChannelPublication 
              v-model="model.channels"
              :channels="channels"
              :product="model"
            /> -->

            <div class="mt-4 mb-5 py-3 border-top text-right footer">
              <!-- <div>
                <a-button
                  size="large"
                  type="primary"
                  ghost
                  class="px-5 mr-3 ml-auto"
                  @click="onCancel"
                >
                  {{ $t('utils.cancel') }}
                </a-button>

                :disabled="submitting || !$can('group1.product.product-master.WRITE')"
                <a-button
                  size="large"
                  type="primary"
                  class="px-5"
                  html-type="submit"
                  :disabled="submitting || !$can('group1.product.product-master.WRITE') || loadingDetail"
                >
                  <a-icon v-if="submitting" type="loading" class="mr-2" />
                  {{ $t('utils.save') }}
                </a-button>
              </div> -->

              <a-button
                size="large"
                type="primary"
                ghost
                class="px-5 mr-3 ml-auto"
                :disabled="submitting"
                @click="onCancel"
              >
                {{ $t('utils.cancel') }}
              </a-button>

              <a-button
                v-if="permission.includes('WRITE') && !$route.query.edit"
                size="large"
                type="primary"
                class="px-5"
                html-type="submit"
                :loading="submitting"
                :disabled="submitting || loadingDetail"
              >
                {{ $t('utils.save') }}
              </a-button>
            </div>
          </fieldset>
        </a-form>
      </ValidationObserver>
    </a-col>
  </a-row>
</template>

<script>
import { defineComponent } from 'vue'
import DetailProduct from './DetailProduct.vue'
import { ValidationObserver } from 'vee-validate'
import PhotoAndVideo from './PhotoAndVideo.vue'
import debounce from 'lodash/debounce'
import TypeAndPrice from './TypeAndPrice/index.vue'
import { useFormula } from './TypeAndPrice/Price/useFormula'
import WeightAndShipping from './WeightAndShipping/index.vue'
// import WarehouseAndInventories from './WarehouseAndInventories/index.vue'
// import ChannelPublication from './Channel/ChannelPublication.vue'
import { getCategoryList, saveProductImages } from '@/api/product'
import { getProductVariantTokopedia } from '@/api/channels/tokopedia'
import { getInventoryListV2, getUom, addWarehouse, addAllocationStock, saveProductInventory } from '@/api/inventory'
import { getShippingUnit, saveProductShipping } from '@/api/shipping'
import { addProductChannel, crudProduct, updateCatalogsProduct, deleteProduct } from '@/api/product'
import { getPriceRange, saveProductPrice } from '@/api/price'
import { getBusinessChannel } from '@/api/business'
import { getWarehouseTokopedia } from '@/api/channels/tokopedia'
import {
  getChannelCategories,
  getChannelAttributes,
  getChannelDraft,
  channelEventProcess,
  syncChannelAttributes,
  updateProductChannel,
  deleteChannelDraft,
} from '@/api/channels/index'
import buildTree from '@/utils/buildTree'

const { fetchPriceFormula } = useFormula()

const DEFAULT_MODEL = () => ({
  id: null,
  status: 'DRAFT',
  detail: {
    brand_id: null,
    category_id: null,
    category_name: '',
    category_tokopedia_id: [],
    condition: 'NEW',
    imported: false,
    long_description: '',
    title: '',
    physical: true,
    vat_type: 'INCLUDE',
  },
  images: [],
  imagesSize: [],
  catalogs: {
    attributes: null,
    options: null,
    items: [
      {
        id: null,
        factory_sku: null,
        images: [],
        option: null,
        price: {
          currency: 'IDR',
          value: 0,
          min_qty: 1,
          price_qty: 1,
          uom: 'PCS',
          price_id: null,
          formula_id: null,
          product_variables: [],
        },
        sku: null,
        status: true,
        title: null,
        ext_catalog_id: null,
      },
    ],
    bundling: {
      type: null,
      bundles: [],
    },
  },
  units: [
    {
      unit: 'PCS',
      numerator: 1,
      denumerator: 1,
      is_must_insurance: true,
      is_free_return: true,
      package: {
        length: null,
        length_unit: 'cm',
        width: null,
        width_unit: 'cm',
        height: null,
        height_unit: 'cm',
        weight: null,
        weight_unit: 'GR',
      },
      collapseOpen: false,
    },
  ],
  inventories: [],
  channels: [],
  warehouse: {
    garansi: '',
    policy: '',
    is_cod_open: false,
  },
})

const parseErrorMessage = msg => {
  let result = 'Coba lagi beberapa saat'
  if (msg && msg.includes('exists')) {
    result = `Kode SKU ${msg.split(' ')[1]} telah terdaftar pada produk lain`
  }
  return 'Oops, produk gagal disimpan! ' + result
}

export default defineComponent({
  name: 'CreateProductForm',
  components: {
    DetailProduct,
    ValidationObserver,
    PhotoAndVideo,
    TypeAndPrice,
    WeightAndShipping,
    // WarehouseAndInventories,
    // ChannelPublication,
  },
  props: {
    business: {
      type: Object,
      required: true,
    },
    product: {
      type: Object,
      default: () => {},
    },
    productIdDraft: {
      type: String,
      default: '',
    },
    draftImage: {
      type: Array,
      default: () => [],
    },
    permission: {
      type: [Object, Array],
      default: () => {},
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      activeSection: 0,
      saving: {
        detail: false,
      },
      /**
       * @type {import('@/types/product').ProductFormModel}
       */
      model: DEFAULT_MODEL(),
      /** @type {import('@/types/warehouse').Warehouse[]} */
      warehouses: [],
      channels: [],
      shippingUnits: [],
      categories: [],
      tokopediaCategories: [],
      tokopediaAttributes: [],
      inventoryList: [],
      tokopediaOptions: {},
      tokopediaValues: {},
      submitting: false,
      loadingDetail: false,
      request_id_temp: '',
      eventProcess: false,
      brands: [],
      detailModel: {},
      catalogsModel: {},
      editable: {
        detail: false,
        photo: false,
        price: false,
        shipping: false,
        inventory: false,
      },
      attribute_lists: [],
      rawAttributeLists: [],
      loadingVariantTokopedia: false,
    }
  },
  computed: {
    sections() {
      return {
        detail: this.$t('product.product_details'),
        gallery: this.$t('product.photo_video'),
        price: this.$t('product.product_type_and_price'),
        shipping: this.$t('product.weight_product_shipping'),
        // inventory: this.$t('product.inventory'),
        // channel: this.$t('product.channel'),
      }
    },
    businessId() {
      return this.business?.business_id || (this.$route.query.business_id ? String(this.$route.query.business_id) : null)
    },
    productId() {
      return this.model.id
    },
    httpHeader() {
      return {
        'Business-Id': this.businessId,
      }
    },
    httpParams() {
      return {
        business_id: this.businessId,
      }
    },
    isNewProduct() {
      return !this.model.id
    },
  },
  watch: {
    product: {
      immediate: true,
      deep: true,
      handler() {
        this.createProductModel()
      },
    },
    productId: {
      immediate: true,
      handler(value) {
        if (value && this.product) {
          this.fetchShippingAndInventoryUnits()
          this.fetchCatalogPrices()
          this.fetchInventory()
        }
      },
    },
    'businessId': {
      immediate: true,
      handler(businessId) {
        this.loadingDetail = true
        Promise.all([
          this.fetchCategories(),
          this.fetchCategoriesTokopedia(),
          this.fetchWarehouse(),
          this.fetchChannels(),
          fetchPriceFormula({ businessId }),
        ])
        .finally(() => {
          this.loadingDetail = false
        })
      },
    },
    'model.detail.category_id'(ids) {
      const id = Number(ids[ids.length - 1])
      this.model.detail.category = !id ? {} : this.findCategory(this.categories, id)
    },
    'model.detail.brand_id'() {
      this.selectBrandAttribute()
    },
  },
  created() {
    this.debouncedSaveDetail = debounce(this.saveDetail, 50)
    this.debouncedSaveImages = debounce(this.saveImages, 500)
    this.debouncedSaveCatalogues = debounce(this.saveCatalogues, 500)
    this.debouncedSaveWeightAndShipping = debounce(this.saveWeightAndShipping, 500)
    this.debouncedSaveWarehousesAndInventories = debounce(this.saveWarehousesAndInventories, 500)
  },
  // mounted() {
  // },
  methods: {
    findCategory(lists, matched) {
      let length = lists.length
      let result = null
      for (let i = 0; i < length; i++) {
        if (lists[i].id == matched) {
          const { id, name } = lists[i]
          return { id, name }
        } else if (lists[i].children) {
          result = this.findCategory(lists[i].children, matched)
          if (result.id) return result
        }
      }
      return {}
    },
    findChildren(item, matched) {
      if (item.id === matched) {
        return [item.id];
      } else if (item.children && item.children.length > 0) {
        for (let i = 0; i < item.children.length; i++) {
          const result = this.findChildren(item.children[i], matched);
          if (result) {
            return [item.id, ...result];
          }
        }
      }
      return null;
    },
    findParent(array, matched) {
      for (let i = 0; i < array.length; i++) {
        const result = this.findChildren(array[i], matched);
        if (result) {
          return result;
        }
      }
      return [];
    },
    changeEditable(e) {
      const key = Object.keys(e)[0];
      this.editable[key] = e[key];
    },
    getEventProcess: debounce(async function(request_id) {
      this.eventProcess = false
      try {
        const response = await channelEventProcess({
          business_id: this.$route.query.business_id || this.$store.state.user.businessList[0].business_id,
          channel_code: 'lazada_id',
          channel_id: this.$route.params.id,
          request_id,
        })
        if (response.data.data.status === "FAILED") {
          this.submitting = false
          this.eventProcess = false
          this.request_id_temp = ''
          this.$message.error(parseErrorMessage('Product Gagal Ditambahkan, Coba Lagi!'), 5)
        } else if (response.data.data.status === "ON_PROGRESS") {
          this.getEventProcess(request_id)
        } else if (response.data.data.status === "FINISHED") {
          this.eventProcess = false
          this.submitting = false
          this.$message.success('Produk berhasil di Simpan!', 5)
          this.$refs.validationObserver.reset()
          if (!this.$route.query.edit) {
            // this.deleteDraftProduct()
            this.$nextTick(() => setTimeout(() => {
              this.submitting = false
              const { workspace_id, business_id, sales_channel_id, channel_code } = this.$route.query
              this.$router.push({
                name: 'product-channel.product-list',
                query: { workspace_id, business_id, sales_channel_id, channel_code },
              })
            }, 150))
          } else {
            this.$emit('getProductDetail')
          }
        }
      } catch (e) {
        if (e.response.status === 404) {
          this.eventProcess = true
          this.request_id_temp = request_id
        } else {
          this.submitting = false
          this.eventProcess = false
          this.request_id_temp = ''
          const { message } = e?.response?.data || {}
          this.$message.error(parseErrorMessage(message), 5)
        }
      }
    }, 2000),
    changeUkuranPaket(val, index) {
      this.model.units.forEach((item, idx) => {
        if (idx === index) {
          item.package = {
            ...item.package,
            length_unit: val,
            width_unit: val,
            height_unit: val,
            // weight_unit: val,
          }
        }
      })
    },
    onChangeUom(uom) {
      this.model.catalogs.items.forEach(item => {
        item.price = { ...item.price, uom }
      })
    },
    runOnDraft(fn) {
      if (this.model.status === 'DRAFT') {
        return fn()
      }
    },
    async fetchInventory() {
      this.loadingDetail = true

      let bodyValue = {
        business_id: this.$route.query.business_id,
        workspace_id: this.$route.query.workspace_id,
        product_id: this.model.id,
      }
      
      await getInventoryListV2(bodyValue)
        .then(({ data }) => {
          this.inventoryList = data
        })
        .catch(err => {
          console.error(err)
        })
        .finally(() => this.loadingDetail = false)
    },
    async createDraft() {
      const response = await getChannelDraft({
        business_id: this.businessId,
        channel_code: 'lazada_id',
        channel_id: this.$route.params.id,
        user_id: this.$store.state.user.id,
      })
      if (response.data.message === 'Success') {
        this.$emit('updateDraftId', response.data.data.product_id)
      }
    },
    async syncCategoriesTokopedia() {
      await syncChannelAttributes({
        business_id: this.businessId,
        channel_code: 'lazada_id',
        channel_id: this.$route.params.id,
        path: 'categories',
        data: undefined,
      })
    },
    async syncAttributesTokopedia() {
      await syncChannelAttributes({
        business_id: this.businessId,
        channel_code: 'lazada_id',
        channel_id: this.$route.params.id,
        path: 'attributes',
        data: undefined,
      })
    },
    async fetchTokopediaAttributes(values) {
      this.loadingVariantTokopedia = true
      if (!(values.length > 0)) {
        this.rawAttributeLists = []
        this.loadingVariantTokopedia = false
        return
      }

      await getChannelAttributes({
        business_id: this.businessId,
        channel_code: 'lazada_id',
        channel_id: this.$route.params.id,
        channel_type: 'annotation',
        params: {
          category_id: values[values.length - 1],
        },
      })
      .then(({ data: { data: response } }) => {
        this.attribute_lists = []
        this.rawAttributeLists = response
        this.loadingVariantTokopedia = false
        this.selectBrandAttribute()
      })
      .catch((err) => {
        console.error(err)
        this.loadingVariantTokopedia = false
      })
    },
    async fetchAttributesTokopedia(id) {
      const { data: { data } } = await getChannelAttributes({
        business_id: this.businessId,
        channel_code: 'lazada_id',
        channel_id: this.$route.params.id,
        channel_type: 'attributes',
        params: {
          type: 2,
          category_id: id,
        },
      })
      this.tokopediaAttributes = data.attributes
    },
    async fetchVariantProduct(values) {
      // this.fetchTokopediaAttributes(values)
      await getProductVariantTokopedia({
        business_id: this.$route.query.business_id,
        channel_id: this.$route.params.id,
        params: {
          category_id: values[values.length - 1],
        },
      })
      .then(({ data: { data: response } }) => {
        response.variant.forEach(variant => {
          let temp = []
          variant.units.forEach(unit => {
            unit.unit_values.forEach(element => {
              const name = variant.variant_id + element.value.replace(' ', '').toLowerCase()
              this.tokopediaValues[name] = {
                attribute_id: variant.variant_id,
                attribute_unit_id: unit.variant_unit_id,
                attribute_value_id: element.variant_unit_value_id,
                attribute_value: element.value,
              }
              temp.push(element.value)
            })
          })
          this.tokopediaOptions[variant.variant_id] = temp
        })
        
        this.tokopediaAttributes = response.variant.map(item => ({
          attribute_id: item.variant_id,
          name: item.name,
        }))
      })
    },
    fetchCategories: debounce(async function() {
      const { data } = await getCategoryList({ params: { ...this.httpParams } })
      this.categories = data.master || []
      const parent = this.product?.detail?.category?.id ? this.findParent(data?.master, this.product.detail.category.id) : []
      if (parent.length) this.model.detail.category_id = parent
    }, 500),
    fetchCategoriesTokopedia: debounce(async function() {
      const { data } = await getChannelCategories({
        business_id: this.businessId,
        channel_code: 'lazada_id',
        channel_id: this.$route.params.id,
      })
      this.tokopediaCategories = buildTree(data.category_list, 'category_id', 'parent_id')
    }, 500),
    async fetchWarehouse() {
      const { data } = await getWarehouseTokopedia({
        business_id: this.businessId,
        channel_id: this.$route.params.id,
      })
      const temp = data?.map(v => {
        const newObj = {
          ...v,
          warehouse_id: v.warehouse_master_id,
          name: v.warehouse_master_name,
        }
        return newObj
      })
      this.warehouses = temp || []
    },
    async fetchChannels() {
      const { data: { data } } = await getBusinessChannel(this.businessId)
      this.channels = data?.channels && data?.channels?.length
        ? data.channels.filter(item => item.id == this.$route.params.id && item.code.includes('tokopedia'))
        : []
    },
    async fetchShippingAndInventoryUnits() {
      const fetchShippingUnitPromise = getShippingUnit({
        product_id: this.productId,
        business_id: this.businessId,
      })

      const fetchInventoryUnitPromise = getUom({
        id: this.productId,
        params: { business_id: this.businessId },
      })

      const [{ data: { data: shippingUnitResponse }}, { data: { data: inventoryUnitResponse }}] = await Promise.all([
        fetchShippingUnitPromise,
        fetchInventoryUnitPromise,
      ])

      const shippingUnits = shippingUnitResponse?.product_units || []
      const inventoryUnits = inventoryUnitResponse?.units || []

      this.model.units = shippingUnits.length
        ? shippingUnits.map(({ unit, ...unitPackage }) => ({
          unit,
          package: unitPackage,
          collapseOpen: false,
          ...(inventoryUnits.find(u => u.unit === unit) || {}),
        }))
        : inventoryUnits.length ? inventoryUnits.map(({ unit, numerator, denumerator }) => {
          // eslint-disable-next-line no-unused-vars
          const { unit: _, ...unitPackage } = shippingUnits.find(u => u.unit === unit) || {}
          return {
            unit,
            numerator,
            denumerator,
            collapseOpen: false,
            package: unitPackage,
          }
        })
        : this.model.units
    },
    async fetchCatalogPrices() {
      const { data: { data } } = await getPriceRange({
        business_id: this.businessId,
        product_id: this.productId,
      })

      this.model.catalogs.items.forEach((item) => {
        const priceData = data.find(p => p.catalog_id === item.id)
        const price = priceData?.price[0]

        if (price) {
          item.price = {
            ...item.price,
            ...price,
          }
        }
      })
    },
    createProductModel() {
      const product = this.product
      const id = product?.product_id
      const img = product?.images
      const catalog = product?.catalogs
      const optonId = this.$route.query.edit ? [product?.catalogs?.[0]?.attribute_details?.[0]?.name] : [product?.catalogs?.[0]?.attribute_details?.[0]?.data?.id]

      if (!id) {
        return
      }

      if (this.$route.name !== 'product-channel.product-synchronize-edit-lazada') {
        // eslint-disable-next-line no-unused-vars
        var { channels, ...detail } = product.detail
      }
      if (this.$route.name === 'product-channel.product-synchronize-edit-lazada') {
        // this.fetchAttributesTokopedia(product.category_details.map(v => {return v.id})[2] || product.category_details.map(v => {return v.id})[1])
      }
      this.model.id = product?.product_id
      this.model.status = this.$route.name === 'product-channel.product-synchronize-edit-lazada' ? product.product_status : product.detail.status
      this.model.detail = this.$route.name === 'product-channel.product-synchronize-edit-lazada' ? {
        ...this.model.detail,
        title: product.title,
        // category_id: product.category.id || null,
        // category_name: product.category.name,
        // category_tokopedia_id: product.category_details.map(v => {return v.id}),
        annotations: product.annotations || [],
        long_description: product.long_description,
        imported: product.is_imported,
        condition: product.is_new ? 'NEW' : 'USED',
        category_details: product.category_details,
      } : {
        ...this.model.detail,
        ...detail,
        category_id: [
          Number(product.detail.category?.id),
        ].filter(Boolean),
        physical: product.detail.physical === null ? true : product.detail.physical,
      },
      this.model.images = img.map((image, index) => (this.$route.name === 'product-channel.product-synchronize-edit-lazada' ? {
        ...image,
        id: image.image_id,
        name: '',
        order: null,
        ref_id: image.channel_image_id,
        slug: null,
        tags: index === 0 ? ["SHOW", "THUMB"] : ["SHOW"],
        url: image.url_list[0],
      } : {
        ...image,
        uploading: false,
        deleted: false,
        file: null,
      }))
      // this.model.channels = (product.channels || []).map(c => ({
      //   id: c.id,
      //   title: c.channel_title,
      //   code: c.channel_code, 
      //   deleted: false,
      // }))

      const catalogItems = catalog.map(item => {
        let findMaster = []
        let findChannel = []
        let stock_master = 0
        let stock_channel = 0
        let allocated = 0
        if (this.$route.query.edit) {
          findMaster = product.stock_masters.find(el => {
            return el.product_id === item.master_product_id && el.catalog_id === item.master_catalog_id
          })
  
          findChannel = product.stock_channels.find(el => el.catalog_id === item.master_catalog_id)
          stock_master = findMaster?.availability || 0
          stock_channel = findChannel?.availability || 0
          allocated = findChannel?.allocate_value || 0
        }

        const temp = this.$route.name === 'product-channel.product-synchronize-edit-lazada' ? {
          id: item.catalog_id,
          factory_sku: null,
          images: this.setCatalogImages(item.images),
          option: {
            [item.attribute_details[0]?.name]: item.attribute_details[0]?.value,
          },
          price: {
            currency: item.price[0].currency,
            value: item.price[0].original_price,
            min_qty: 1,
            price_qty: 1,
            uom: product.base_unit,
            price_id: null,
            formula_id: null,
            product_variables: [],
          },
          sku: item.sku,
          status: item.status === 'CONFIRMED' ? true : false,
          status2: item.status,
          title: item.title,
          ext_catalog_id: null,
          attributes: item.attribute_details,
          warehouse_list: item.warehouse_list || [],
          stock_master,
          stock_channel,
          allocated,
        } : {
          ...item,
          price: DEFAULT_MODEL().catalogs.items[0].price,
        }

        return temp
      })
      this.model.catalogs = {
        attributes: this.$route.name === 'product-channel.product-synchronize-edit-lazada' ? {
          [product.catalogs[0].attribute_details[0]?.name]: product.catalogs.map(v => {return v.attribute_details.map(item => {return item.value})?.toString()}),
        } : product.attributes,
        options: this.$route.name === 'product-channel.product-synchronize-edit-lazada' ? optonId : product.options,
        items: catalogItems.length ? catalogItems : [...DEFAULT_MODEL().catalogs.items],
        bundling: {
          type: product.bundling?.bundling_type || null,
          bundles: product.bundling?.bundles || [],
        },
      }
      if (this.$route.name === 'product-channel.product-synchronize-edit-lazada') {
        this.model.units = [
          {
            unit: product.base_unit,
            numerator: 1,
            denumerator: 1,
            package: {
              ...product.shipping_unit,
            },
            collapseOpen: false,
            is_must_insurance: product.must_insurance,
            is_free_return: product.free_return,
          },
        ]
        this.model.catalogs.variant_count = product.variant_count
        this.model.inventories = product.inventory?.map(item => {
          const temp = {
            business_id: item.business_id,
            id: item.warehouse_id,
            is_main: true,
            catalogs: product.catalogs.map(v => {
              const data = {
                id: v.master_catalog_id === item.catalog_id ? v.catalog_id : null,
                availability: v.master_catalog_id === item.catalog_id ? item.availability : 0,
                allocated: v.master_catalog_id === item.catalog_id ? item.allocate_value : 0,
                on_hand: 0,
                pre_order_status: false,
                sellable: 0,
                uom: v.master_catalog_id === item.catalog_id ? item.uom : null,
                warehouse_id: item.warehouse_id,
              }
              return data
            }),
          }
          return temp
        })
      }
      this.detailModel = {
        title: product.title,
        long_description: product.long_description,
      }
      this.catalogsModel = product.catalogs.map((item) => (this.$route.name === 'product-channel.product-synchronize-edit-lazada' ? {
          id: item.catalog_id,
          factory_sku: null,
          images: item.images,
          option: {
            [item.attribute_details[0]?.name]: item.attribute_details[0]?.value,
          },
          price: {
            currency: item.price[0].currency,
            value: item.price[0].original_price,
            min_qty: 1,
            price_qty: 1,
            uom: product.base_unit,
            price_id: null,
            formula_id: null,
            product_variables: [],
        },
        sku: item.sku,
        status: item.status === 'CONFIRMED' ? true : false,
        status2: item.status,
        title: item.title,
        ext_catalog_id: null,
        attributes: item.attribute_details,
        warehouse_list: item.warehouse_list || [],
        } : {
        ...item,
        price: DEFAULT_MODEL().catalogs.items[0].price,
      }))
      this.model.inventories = [{
        is_main: true,
        catalogs: catalogItems,
        id: '',
      }]

      if (this.$route.query.edit) {
        this.fetchVariantProduct(this.model.detail.category_tokopedia_id)
      }
    },
    setCatalogImages(images) {
      const findImage = this.model.images.find(item => item.url === images[0].url_list[0])
      
      const result = images.map(item => {
        return {
          refId: findImage?.ref_id ? findImage.ref_id : 0,
          imagePath: item.url_list[0],
          thumbnailUrl: findImage?.thumb_url_list?.[0],
          id: findImage?.id,
        }
      })
      return result
    },
    async saveDetail(ctx, showMessage = true) {
      if (this.saving.detail) return
      this.saving.detail = true

      this.saveOrUpdateDetailProduct(showMessage)

      this.saving.detail = false
    },
    async saveOrUpdateDetailProduct(showMessage = true) {
      // eslint-disable-next-line no-unused-vars
      const { detail, ...model } = this.model
      try {
        const { data: { data } } = await crudProduct({
          business_id: this.business.business_id,
          id: model.id || undefined,
          ...this.model.detail,
          is_new: this.isNewProduct,
        })

        this.model.id = data.id
        // this.model.status = data.status
        if (showMessage) {
          this.$message.success(this.model.id ? 'Perubahan berhasil disimpan' : 'Draft produk berhasil disimpan', 5)
        }
        if (!this.productIdDraft) { 
          this.createDraft()
        }
      } catch (err) {
        if (showMessage) {
          const { message } = err?.response?.data || {}
          this.$message.error(parseErrorMessage(message), 5)
        }
        throw err
      }
    },
    async saveImages(images, showMessage = true) {
      try {
        const undeletedImages = images.filter(image => !image.deleted)
        const product_id = this.model.id
        // Hit service if product_id available
        if (product_id && !this.$route.query.edit) {
          await saveProductImages({
            product_id,
            business_id: this.business.business_id,
            images: undeletedImages.map(({ id, name, url, ref_id, order, tags }) => ({
              id,
              name,
              url,
              ref_id,
              order,
              tags,
            })),
            images_delete: images.filter(image => image.deleted).map(({ ref_id }) => ref_id),
            status: this.model.status,
          })
        }

        this.model.images = undeletedImages

        // if (this.productIdDraft) { 
        //   const imageProduct = this.model.images.map(item => {
        //       const temp = {
        //           id: item.id,
        //       }
        //       return temp
        //   })
        //   const imageChart = this.model.imagesSize.map(item => {
        //       const temp = {
        //           id: item.id,
        //       }
        //       return temp
        //   })
        //   const imagetemp = [...imageProduct, ...imageChart] 
        // }
        if (showMessage && product_id && !this.$route.query.edit) this.$message.success('Gambar produk berhasil disimpan', 5)
      } catch (err) {
        this.$message.error('Oops, gambar produk gagal disimpan', 5)
        throw err
      }
    },
    async saveCatalogues(ctx, showMessage = true) {
      if (!this.productId) {
        // we can save the pending state, once product id is available we can save the catalogues
        return
      }

      try {
        const isCatalogIdFilled = this.model.catalogs.items.every((item) => !!item.id || (!item.id && !item.status)) 
        const { units, catalogs: { bundling } } = this.model
        const saveCatalogItems = async () => {
          const { data: { data: { catalogs } } } = await updateCatalogsProduct({
            attributes: this.model.catalogs.attributes,
            options: this.model.catalogs.options,
            business_id: this.business.business_id,
            product_id: this.model.id,
            catalogs: this.model.catalogs.items.map(item => ({
              ...item,
              sku: item.sku?.toUpperCase(),
              factory_sku: item.factory_sku?.toUpperCase(),
              price: undefined,
              title: item.title || [
                this.model.detail.title,
                (Object.values(item.option || {}) || []).join(', '),
              ].filter(Boolean).join(' - '),
            })),
            bundling: bundling && bundling.type && bundling.bundles.length ? {
              bundling_type: bundling.type,
              bundles: bundling.bundles.map(item => ({
                catalog_id: item.catalog_id,
                quantity: item.quantity,
                uom: item.uom || units[0].unit || null,
              })),
              product_id: this.productId,
            } : null,
            // bundling: {
            //   bundles: [],
            //   type: 'SOFT',
            //   product_id: this.model.id,
            // },
          })

          this.model.catalogs.items.forEach((item) => {
            const catalog = catalogs 
              // ? catalogs[index] 
              ? catalogs.find(c => item.id ? c.id === item.id : c.sku === item.sku)
              : null

            if (catalog) {
              item.id = catalog.id
            }
          })
        }

        // const saveCatalogPromise = saveCatalogItems()
        // if catalog id is not filled, then wait to save catalog items first, otherwise we can call savePrice parallelly
        // if (!isCatalogIdFilled) await saveCatalogPromise

        if (!isCatalogIdFilled) await saveCatalogItems()

        this.$nextTick(async () => await this.savePrice())
      } catch (err) {
        if (showMessage) {
          this.$message.error('Oops, catalog produk gagal disimpan', 5)
          if(err?.response?.data?.message) this.$message.error(err?.response?.data?.message, 5)
        }
        throw err
      }
    },
    async savePrice() {
      const { data: response } = await saveProductPrice({
        business_id: this.business.business_id,
        product_id: this.model.id,
        catalog_price: this.model.catalogs.items
          .filter(i => i.id)
          .map(item => ({
            catalog_id: item.id,
            price: [item.price],
          })),
      })

      const catalogPrices = response?.data?.catalog_price || []

      catalogPrices.forEach(catalogPrice => {
        const { price, catalog_id } = catalogPrice
        const catalog = this.model.catalogs.items.find(c => c.id === catalog_id)

        const mainPrice = price[0]

        catalog.price.price_id = mainPrice.price_id
      })
    },
    async saveWeightAndShipping(ctx, showMessage = true) {
      try {
        await Promise.all([
          this.saveOrUpdateDetailProduct(showMessage),
          saveProductShipping({
            business_id: this.business.business_id,
            product_id: this.model.id,
            product_units: this.model.units.map((unit) => ({ unit: unit.unit, ...unit.package })),
            product_units_delete: [],
            status: this.model.status,
          }),
          saveProductInventory({
            business_id: this.business.business_id,
            product_id: this.model.id,
            units: this.model.units.map(({ unit, denumerator, numerator }) => ({ unit, numerator, denumerator })),
            units_delete: [],
          }),
        ])
      } catch (err) {
        if (showMessage) this.$message.error('Oops, weight dan shipping produk gagal disimpan', 5)
        throw err
      }
    },
    async saveWarehousesAndInventories(ctx, showMessage = true, warehouses) {
      try {
        await addWarehouse({
          business_id: this.businessId,
          data: {
            business_id: this.businessId,
            product_id: this.model.id,
            status: this.model.status,
            warehouses: warehouses || this.model.inventories,
          },
        })
      } catch (err) {
        if (showMessage) this.$message.error('Oops, inventory produk gagal disimpan', 5)
        throw err
      }
    },
    async saveChannels(showMessage = true) {
      const channels = this.channels.filter((c) => !c.deleted).map(({id}) => id)
      const deletedChannels = this.channels.filter((c) => c.deleted).map(({id}) => id)

      if (!channels.length && !deletedChannels.length) return 

      try { 
        await addProductChannel({
          business_id: this.businessId,
          product_id: this.model.id,
          data: {
            business_id: this.businessId,
            product_id: this.model.id,
            status: this.model.status,
            channels,
            channels_delete: deletedChannels,
          },
        })
      } catch (err) {
        if (showMessage) {
          const { message } = err?.response?.data || {}
          this.$message.error(parseErrorMessage(message), 5)
        }
        throw err
      }
    },
    generateUUID() {
      let d = Date.now();
      const uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
        const r = (d + Math.random()*16)%16 | 0;
        d = Math.floor(d/16);
        return (c=='x' ? r : (r&0x3|0x8)).toString(16);
      });
      return uuid.replace(/(\w{8})(\w{4})(\w{4})(\w{4})(\w{12})/, '$1-$2-$3-$4-$5');
    },
    async createAllocation() {
      if (this.$route.query.edit) this.submitting = true
      try { 
        const id = this.$route.name !== 'product-channel.product-synchronize-edit' ? this.productIdDraft : this.model.id
        const response = await addAllocationStock({
          request_id: this.generateUUID(),
          business_id : this.businessId,    
          product_id : this.$route.query.edit ? this.model.catalogs.items[0].master_product_id : id,
          base_uom : this.model.units?.[0]?.unit,
          datas : this.model.inventories.map(v => {
            const data = {
              warehouse_id: this.warehouses.filter(item => {return item.warehouse_master_id === v.id})?.[0]?.warehouse_master_id,
              items: v.catalogs.map(item => {
                const temp = {
                  id: '',
                  catalog_id: this.$route.query.edit ? item.master_catalog_id : item.id,
                  channel_id : this.$route.params.id,
                  channel_code : this.$route.query.channel_code,
                  allocate_value : item.allocated,
                  availability: Number(((item.availability * item.allocated)/100)?.toFixed(0)) || 0,
                  // availability: item.availability,
                }
                return temp
              }),
            }
            return data
          }),
        })
        if (response.data?.status === 200) {
          this.$message.success(response.data.message, 5)
        }
        if (this.$route.query.edit) this.submitting = false
      } catch (err) {
        if (err) {
          const { message } = err?.response?.data || {}
          this.$message.error(parseErrorMessage(message), 5)
        }
        this.submitting = false
        throw err
      }
    },
    getFirstError() {
      const errors = this.$refs.validationObserver.errors
      for (const key in errors) {
        if (errors[key].length) return errors[key][0]
      }
      return null
    },
    async saveEdit(payload) {
      this.submitting = true
      try {
        const response = await updateProductChannel({
          business_id: this.businessId,
          channel_code: 'lazada_id',
          channel_id: this.$route.params.id,
          user_id: this.$store.state.user.id,
          product_id: this.model.id,
          data: payload,
        })
        if (response.data.message?.toLowerCase() === 'success') {
          this.request_id_temp = response.data.data.request_id
          this.getEventProcess(response.data.data.request_id)
        }
      } catch(e) {
        this.submitting = false
        const { message } = e?.response?.data || {}
        this.$message.error(parseErrorMessage(message), 5)
        this.$emit('getProductDetail')
      }
    },
    async saveProducts() {
      this.submitting = true
      const valid = await this.$refs.validationObserver.validate()

      if (!valid) {
        this.submitting = false
        const firstErrorElement = document.querySelector('.ant-form-explain')
        if (firstErrorElement) {
          window.scrollTo({
            behavior: 'smooth',
            top: firstErrorElement.getBoundingClientRect().top + window.pageYOffset - 225,
          })
        }
        return this.$message.error("Beberapa bidang tidak valid, silahkan isi terlebih dahulu") // Beberapa bidang masih kosong
      }

      // await this.createAllocation()

      /** @NOTES : MN-231 */
      const parseWarehouses = [...this.model.inventories].map(item => ({
        ...item,
        catalogs: item.catalogs.map(cat => ({ ...cat, id: ('' + cat.id).startsWith('TEMP_CATALOG_ID_') ? null : cat.id }) ),
      }))

      try {
          let catalogs = []
          this.model.catalogs.items?.forEach(item => {
            let attributes = []
            for(const prop in item.option) {
              attributes.push({
                attribute_name: prop,
                attribute_value: item.option[prop],
              })
            }

            catalogs.push({
              sku: item.sku,
              status: 'LIMITED',
              price: {
                currency: item.price.currency,
                value: item.price.value,
              },
              attributes,
              warehouse: {
                stock: 1,
              },
            })
          })

          let annotations = []
          this.attribute_lists.forEach(attr => {
            attr.attribute_value_list.forEach(val => {
              annotations.push(val.value_id)
            })
          })

          const imageProduct = this.model.images.map(item => {
              const temp = {
                  id: item.id,
              }
              return temp
          })
          const imageChart = this.model.imagesSize.map(item => {
              const temp = {
                  id: item.id,
              }
              return temp
          })
          const imagetemp = [...imageProduct, ...imageChart]  
          const data = {
            detail: {
              title: this.model.detail.title,
              long_description: this.model.detail.long_description,
              category: {
                id: this.model.detail.category_tokopedia_id[3]
                || this.model.detail.category_tokopedia_id[2] 
                || this.model.detail.category_tokopedia_id[1] 
                || this.model.detail.category_tokopedia_id[0],
              },
              is_new: this.model.detail.condition === 'NEW' ? true : false,
              is_must_insurance: this.model.units[0].is_must_insurance,
              is_free_return: this.model.units[0].is_free_return,
              min_order: 1,
              images: imagetemp,
              annotations,
            },
            catalogs,
            product_unit: {
              width: this.model.units?.[0]?.package?.width,
              length: this.model.units?.[0]?.package?.length,
              height: this.model.units?.[0]?.package?.height,
              weight: this.model.units?.[0]?.package?.weight,
              weight_unit: this.model.units?.[0]?.package?.weight_unit,
            },
          }

          const id = this.$route.name !== 'product-channel.product-synchronize-edit' ? this.productIdDraft : this.model.id
          const response = await addChannelProduct({
            method: this.$route.name !== 'product-channel.product-synchronize-edit' ? 'post' : 'put',
            business_id: this.businessId,
            channel_code: 'lazada_id',
            channel_id: this.$route.params.id,
            user_id: this.$store.state.user.id,
            product_id: id,
            data,
          })

          if (response.data.message === 'Success') {
            this.request_id_temp = response.data.data.request_id
            this.getEventProcess(response.data.data.request_id)
          }
      } catch (err) {
        this.submitting = false
        const { message } = err?.response?.data || {}
        this.$message.error(parseErrorMessage(message), 5)
        throw err
      }
    },

    separateObject(obj) {
      const res = [];
      const keys = Object.keys(obj);
      keys.forEach(key => {
          res.push({
            [key]: obj[key],
          });
      });
      return res;
    },

    beforeRouteLeave(to, from, next) {
      const isDraftProduct = this.model.status === 'DRAFT'
      const dirty = this.$refs.validationObserver.flags.dirty

      if (!this.permission.includes('WRITE')) return next()

      if ((!dirty && !(this.model.id && isDraftProduct) && !this.productIdDraft)) return next()

      if (this.request_id_temp) return next()

      this.$confirm({
        title: 'Data Belum Tersimpan',
        icon: () => null,
        content: isDraftProduct 
          ? 'Apakah Anda yakin akan menutup halaman ini, Data yang sudah diisi tidak akan disimpan' 
          : 'Perubahan akan hilang saat anda keluar dari halaman ini',
        okText: isDraftProduct ? 'Ya, Keluar dari halaman ini' : 'Ya, Keluar dari halaman ini',
        class: 'leaving-product-confirm',
        onOk: () => {
          this.deleteDraftProduct()
          next()
        },
      })

      next(false)
    },
    async deleteDraftProduct() {
      const isDraft = this.model.status === 'DRAFT'
      if (isDraft && this.model.id || this.productIdDraft) {
        await deleteProduct(this.model.id)
        await deleteChannelDraft({
          channel_code: this.$route.query.channel_code,
          channel_id: this.$route.params.id, 
          user_id: this.$store.state.user.id, 
          product_id: this.productIdDraft, 
          business_id: this.businessId,
        })
      }
    },
    onCancel() {
      const redirectUrl = this.$route.query.redirect_url

      if (redirectUrl) {
        return this.$router.push(redirectUrl)
      }

      return this.$router.push({
        name: 'product-channel.product-list',
        query: {
          workspace_id: this.$route.query.workspace_id,
          business_id: this.$route.query.business_id,
        },
      })
    },
    onChangeChannelAttribute(value) {
      const isExist = this.attribute_lists.findIndex((obj) => obj.attribute_id === value.attribute_id)
      if (isExist >= 0) {
        this.attribute_lists[isExist] = value
      } else {
        this.attribute_lists.push(value)
      }
    },
    selectBrandAttribute() {
      if(!this.model.detail.brand_id || !this.rawAttributeLists.length) return

      let found = false
      const attrIndex = this.rawAttributeLists.findIndex(attr => attr.variant.toLowerCase().includes('merek'))
      if(attrIndex > -1) {
        const findValue = this.rawAttributeLists[attrIndex].values.find(value => value.id == this.model.detail.brand_id || value.name.toLowerCase() == this.model.detail.brand_id.toLowerCase())
        if(findValue?.id) {
          const attributeIndex = this.attribute_lists.findIndex(attribute => attribute.attribute_name.toLowerCase().includes('merek'))
          if(attributeIndex > -1) this.attribute_lists.splice(attributeIndex, 1)

          this.attribute_lists.push({
            attribute_id: 999,
            attribute_name: "Merek",
            attribute_value_list: [{
              original_value_name: findValue.name,
              value_id: findValue.id,
            }],
            is_mandatory: false,
          })
          found = true
        }
      }

      if(!found) {
        const attributeIndex = this.attribute_lists.findIndex(attribute => attribute.attribute_name.toLowerCase().includes('merek'))
        if(attributeIndex > -1) this.attribute_lists.splice(attributeIndex, 1)
      }
    },
  },
})
</script>

<style scoped lang="scss">
.footer {
  bottom: 0;
  background-color: #F8F9FA;
  // border-top: 1px solid #efefef;
  z-index: 3;
}

ol {
  list-style: none;
  top: 130px;

  > li {
    position: relative;
    padding: .5rem 2rem;

    a {
      color: #999;
    }

    &::before {
      position: absolute;
      content: '';
      width: 10px;
      height: 10px;
      border-radius: 20rem;
      background: #999;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
    }

    &::after {
      position: absolute;
      content: '';
      width: 1px;
      background: #999;
      top: 50%;
      left: 4.5px;
      transform: translateY(-50%);
      height: 100%;
      z-index: -1;
    }

    &:first-child::after {
      height: 50%;
      transform: none;
    }

    &:last-child::after {
      height: 50%;
      transform: none;
      top: 0;
    }

    &.active {
      a {
        color: var(--color-black);
        font-weight: 500;
      }

      &::before {
        background: var(--color-accent);
      }
    }
  }
}
</style>

<style lang="scss">
[data-kit-theme="default"] {
  .leaving-product-confirm {
    text-align: center;

    .ant-modal-confirm-title {
      font-size: 24px;
      font-weight: 500;
      margin-bottom: 12px;
    }

    .ant-modal-confirm-content {
      color: #999;
    }

    .ant-modal-confirm-btns {
      margin-top: 1rem;
      display: flex;
      width: 100%;
      flex-direction: column-reverse;

      button {
        width: 100%;
        margin: 15px 0 0 0 !important;
        height: 45px;
        border: none;
      }
    }
  }
}
</style>
