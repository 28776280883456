<script>
import { computed, defineComponent, reactive, ref, watch } from 'vue' // watchEffect
import { useFormula } from './useFormula'
import FormItem from '@/components/Input/FormItem.vue'
import PriceInput from './PriceInput.vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import debounce from 'lodash/debounce'
import { useCurrency } from '@/composable/useCurrency'

export default defineComponent({
  components: { 
    PriceInput,
    ValidationObserver,
    ValidationProvider,
    FormItem,
  },
  props: {
    business: {
      type: Object,
      required: true,
    },
    // catalog price model
    value: {
        type: Object,
        required: true,
    },
    unit: {
      type: String,
      default: () => undefined,
    },
  },
  setup(props, { emit }) {
    const price = ref(() => props.value);
    watch(() => props.value, (value) => {
      price.value = value;
    }, { immediate: true });

    const { formulas, fetchFormulaDetail, calculate } = useFormula();
    const filterFormulaOption = (value, option) => {
      return String(option.componentOptions.children[0].text).toLowerCase().indexOf(String(value).toLowerCase()) >= 0
    }

    const selectorForm = ref()
    const selector = reactive({
      open: false,
      form: {
        formula_id: null,
        variables: [],
        currency: 'IDR',
        value: 0,
        uom: 'PCS',
      },
    })
    watch(() => selector.open, (open) => {
      // setSelectorDefaultFormValue()
      const { formula_id, currency, value, uom } = props.value;
      selector.form = {
        formula_id: open ? formula_id : null,
        variables: [],
        currency: open ? currency : 'IDR',
        value: open ? value : null,
        uom: open ? uom : 'PCS',
      }
    })

    const detail = ref(null);
    watch(() => [selector.form.formula_id, selector.open], async ([formulaId, open]) => {
      detail.value = null

      if (formulaId && open) {
        selector.form.value = null
        detail.value = await fetchFormulaDetail({ businessId: props.business.business_id, formulaId })
      }
    })
    watch(detail, () => {
      selector.form.variables = [
        ...(detail.value?.product_variables || []).map(variable => {
          const existing = props.value.product_variables.find(productVariable => productVariable.id === variable.id)
          variable.value = existing?.value || variable.value
          return variable
        }),
      ]
    })

    const calculating = ref(false)
    const productVariableFilled = computed(() => {
      return !selector.form.variables.some(({ value }) => (!value && value !== 0) || isNaN(value))
    })
    const calculatePrice = debounce(async () => {
      const productVariables = selector.form.variables
      if (!productVariables || !detail.value?.id || !selector.open) return // bail out when productVariables is not ready
      if (productVariables.length && !productVariableFilled.value) return // bail out when some productVariables value is empty

      calculating.value = true
      selector.form.value = null
      
      try {
        selector.form.value = await calculate({ 
          businessId: props.business.business_id,
          formulaId: detail.value.id,
          productVariables,
        })
      } catch (e) {
        selector.form.value = null
      }

      calculating.value = false
    }, 325)
    watch(() => [selector.form.variables, productVariableFilled.value, selector.open], () => calculatePrice(), { deep: true })


    const onSelectorSubmit = async () => {
      if (!(await selectorForm.value.validate())) return
      
      const { variables, ...form } = selector.form
      const { formula_variables, global_variables } = detail.value

      const price = {
        ...props.value,
        ...form,
        product_variables: variables,
        formula_name: detail.value.name,
        formula_variables,
        global_variables,
      }

      emit('input', price)

      selector.open = false
    }

    const { format, parseLocaleNumber } = useCurrency()

    return {
      formulas,
      filterFormulaOption,
      price,
      selectorForm,
      selector,
      detail,
      calculating,
      onSelectorSubmit,
      productVariableFilled,
      format,
      parseLocaleNumber,
    };
  },
})
</script>

<template>
  <div class="price-formula--selected">
    <div class="font-weight-bold mb-3">
      {{ $t('price_formula.price_formula') }}
    </div>

    <table>
      <tr>
        <td class="py-1">
          {{ $t('price_formula.formula_name') }}
        </td>
        <td class="px-3">
          :
        </td>
        <td>
          <span>
            {{ price.formula_name || '-' }}
          </span>
          <a-button
            type="primary"
            size="small"
            icon="edit"
            class="ml-4 price-formula--selected--change-btn"
            @click="selector.open = true"
          >
            {{ $t('utils.change') }}
          </a-button>
        </td>
      </tr>
      <tr>
        <td class="py-1">
          {{ $t('product.calc_price') }}
        </td>
        <td class="px-3">
          :
        </td>
        <td>{{ price.value !== null ? format(price.value, price.currency) : '-' }} / {{ price.uom }}</td>
      </tr>

      <ValidationProvider v-slot="{ errors }" rules="required" name="Formula Harga">
        <input v-model="price.formula_id" type="hidden" />
        <span v-if="errors.length" class="ant-form-explain text-danger">{{ errors[0] }}</span>
      </ValidationProvider>
    </table>

    <a-modal
      v-model="selector.open"
      :footer="false"
      :dialog-style="{ top: '20px' }"
      :title="$t('price_formula.price_formula')"
    >
      <ValidationObserver
        v-if="selector.open"
        ref="selectorForm"
        class="price-formula--selector-form"
      >
        <a-form-item :label="$t('price_formula.formula_name')">
          <a-select 
            v-model="selector.form.formula_id"
            show-search
            :options="formulas.map(formula => ({
              value: formula.id,
              label: formula.name,
            }))"
            placeholder="Pilih formula harga"
            :filter-option="filterFormulaOption"
            class="h-48px w-100"
          />
        </a-form-item>

        <template v-if="detail">
          <div 
            class="mb-4 bg-info text-white mt-n4"
            style="padding: 12px 16px; border-radius: 4px; font-size: 85%"
          >
            {{ detail ? detail.formula : null }}
          </div>
          <div v-if="detail.global_variables.length" class="mb-4 price-formula--selector-variables">
            <b class="d-block mb-2">
              Variable Global
            </b>
            <table class="">
              <tr v-for="globalVariable in (detail.global_variables || [])" :key="globalVariable.id">
                <td class="py-1">
                  {{ globalVariable.symbol }}
                </td>
                <td class="px-2">
                  :
                </td>
                <td>
                  {{ globalVariable.value }}
                </td>
              </tr>
            </table>
          </div>

          <div v-if="detail.formula_variables.length" class="mb-4 price-formula--selector-variables">
            <b class="d-block mb-2">
              Variable Formula
            </b>
            <table class="">
              <tr v-for="variable in (detail.formula_variables || [])" :key="variable.id">
                <td class="py-1">
                  {{ variable.symbol }}
                </td>
                <td class="px-2">
                  :
                </td>
                <td>
                  {{ variable.value }}
                </td>
              </tr>
            </table>
          </div>

          <div v-if="selector.form.variables.length" class="price-formula--selector-variables mb-4">
            <b class="d-block mb-2">
              Variable Produk
            </b>
            <template v-for="v in selector.form.variables">
              <FormItem :key="v.id" tag="div" rules="required" :label="v.symbol">
                <a-input-number
                  v-model="v.value"
                  :formatter="value => format(value)"
                  :parser="value => parseLocaleNumber(value)"
                  class="h-48px w-100 required"
                />
              </FormItem>
            </template>
          </div>

          <div class="relative mb-5 price-formula--selector-total">
            <PriceInput
              v-model="selector.form"
              :label="$t('product.totalItemPrice')"
              disabled
              :unit="unit"
              class=""
              @changeuom="val => $emit('changeuom', val)"
            />
            <a-spin v-if="calculating" tip="Loading..." />
          </div>
        </template>

        <div class="d-flex mt-5 pt-4 mx-n4 px-4 border-top">
          <a-button
            type="primary"
            size="large"
            ghost
            block
            class="mr-1"
            @click="() => selector.open = false"
          >
            {{ $t('utils.cancel') }}
          </a-button>
          <a-button
            type="primary"
            size="large"
            block
            class="ml-1"
            :disabled="selector.form.value === null || !productVariableFilled"
            @click.prevent="onSelectorSubmit"
          >
            {{ $t('utils.apply') }}
          </a-button>
        </div>
      </ValidationObserver>
    </a-modal>
  </div>
</template>

<style lang="scss">
.price-formula {
  &--selected {
    line-height: 1.5;

    &--change-btn {
      font-size: 85% !important;
    }
  }

  &--selector-form {
    .ant-form-item-label {
      line-height: 1.5;
    }
  }

  &--selector-variables {
    .ant-form-item {
      margin-bottom: .5rem;
    }

    .ant-form-item-label {
      line-height: 1.5;
      font-size: 85%;
      background-color: white;
      padding: 0 6px;
      margin-bottom: -1.25rem;
      z-index: 1;
      margin-left: .5rem;
      margin-top: 0;

      > label {
        margin: 0;

        &::after {
          content: none !important;
        }
      }
    }

    .ant-form-explain {
      font-size: 90%;
      margin-top: .25rem;
    }
  }

  &--selector-total {
    position: relative;

    input[disabled] {
      background-color: white !important;
      color: var(--kit-color-primary) !important;
      font-weight: 500;
    }

    .ant-spin {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      background: rgba(244, 244, 244, 0.8);
      > * {
        margin-bottom: .25rem;
        margin-top: .25rem;
      }
    }
  }
}
</style>
