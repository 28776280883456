<template>
  <div>
    <template v-if="errors.code">
      <a-result :status="String(errors.code)" :title="errors.code" :sub-title="errors.message">
        <template #extra>
          <a-button type="primary" @click="$router.back()">
            Back to previous page
          </a-button>
        </template>
      </a-result>
    </template>
    <template v-else>
      <div
        class="mb-4"
        style="position: sticky; top: 78px; z-index: 5; background-color: white; margin: -20px -35px 0; padding: 15px 30px;"
      >
        <a-breadcrumb
          separator=">"
        >
          <a-breadcrumb-item>
            <router-link :to="{ path: '/product', query: { business_id: businessId, workspace_id: $route.query.workspace_id } }">
              {{ $t('product.title') }}
            </router-link>
          </a-breadcrumb-item>
          <a-breadcrumb-item>
            <router-link :to="{ path: '/product', query: { business_id: businessId, workspace_id: $route.query.workspace_id } }">
              {{ $t('product.listProduct') }}
            </router-link>
          </a-breadcrumb-item>
          <a-breadcrumb-item>
            {{ business.business_title }}
          </a-breadcrumb-item>
          <a-breadcrumb-item>
            Ubah Produk
          </a-breadcrumb-item>
        </a-breadcrumb>
      </div>
      <CreateProductForm
        ref="form"
        :business="business"
        :product="product"
        :permission="permission"
        :loading="loading"
        @getProductDetail="getProductDetail"
      />
    </template>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import { getDetailProductChannel } from '@/api/channels/index'
import CreateProductForm from '@/components/Store/Lazada/ProductForm/index.vue'

export default defineComponent({
  name: 'EditProductChannel',
  components: { CreateProductForm },
  beforeRouteLeave(to, from, next) {
    return this.$refs.form ? this.$refs.form.beforeRouteLeave(to, from, next) : next()
  },
  data() {
    return {
      product: null,
      errors: {
        message: null,
        code: null,
      },
      loading: false,
    }
  },
  computed: {
    productId() {
      const id = this.$route.query.id
      return Array.isArray(id) ? id.join(',') : id
    },
    
    /**
     * @returns {string}
     */
    businessId() {
      return String(this.$route.query['business_id'] || '')
    },

    /**
     * @returns {import('@/types/utils').Business}
     */
    business() {
      return this.$store.state.user.businessList.find(business => business.business_id === this.businessId) || {}
    },
    permission() {
      return this.$store.getters['user/can']('product-list')
    },
  },
  // watch: {
  //   permission(newValue) {
  //     if(!newValue.length) {
  //       this.$router.push({path: '/error/403', query: {...this.$route.query}})
  //     }
  //   },
  // },
  mounted() {
    this.$store.commit('products/SET_CHANNEL', true)
    this.getProductDetail()
  },
  methods: {
    async getProductDetail() {
      if (!this.productId) return

      this.loading = true
      this.errors.message = null
      this.$store.commit('productChannel/SET_PRODUCT_ID', this.productId)
      
      try {
        const { data: { data } } = await getDetailProductChannel({
          business_id: this.businessId,
          channel_code: this.$route.query.channel_code,
          channel_id: this.$route.params.id,
          product_id: this.productId,
        })

        this.loading = false
        this.product = data[0]
      } catch (err) {
        const code = err.response?.status
        this.loading = false
        this.errors.code = code
        this.errors.message = code === 404 ? 'Produk tidak ditemukan' : (err.response?.data?.message || 'Terjadi kesalahan')
      }
    },
  },
})
</script>
